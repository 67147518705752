import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceConfigService } from '../workspace/workspace-config.service';
import { ManualDiffAssay } from './manual-diff.model';
import { AppStateService } from '../../app-state.service';
import { KeyboardAction, KeyboardService, LoggerService, ModalContainerService } from '@lims-common-ux/lux';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, switchMap, tap, Subscription } from 'rxjs';
import { ManualDiffCount, ManualDiffSaveCount, ManualDiffService } from './manual-diff.service';
import { ModalContainerComponent } from '@lims-common-ux/lux/lib/modal-container/modal-container.component';

@Component({
  selector: 'app-manual-diff',
  templateUrl: './manual-diff.component.html',
  styleUrls: ['./manual-diff.component.scss', './manual-diff-numpad.scss'],
})
export class ManualDiffComponent implements OnInit, OnDestroy {
  @Input()
  accessionAssays;

  @Input()
  disabled: boolean;

  @ViewChild('manualDiffTriggerButton')
  manualDiffTriggerButton!: ElementRef;

  @ViewChild('warningQuitButton')
  warningQuitButton!: ElementRef;

  @ViewChild('targetSelect')
  targetSelect!: ElementRef;

  @ViewChild('modalContent')
  modalContent: ModalContainerComponent;

  audioPromptComplete: HTMLAudioElement;
  audioPromptWarn: HTMLAudioElement;

  isModalOpen = false;

  manualDiffAssays: ManualDiffAssay[];

  manualDiffNumpadAssays: ManualDiffAssay[] = [];

  manualDiffCounts: ManualDiffCount[];

  manualDiffCountsDebounce = 300;

  manualDiffForm: FormGroup;

  manualDiffDisplayTotal: number;

  manualDiffDisplayTotalVsTarget: number;

  manualDiffSaveSub: Subscription;

  visible: boolean;

  suspendAudioPrompt = false;
  private _hasChanges = false;
  public get hasChanges() {
    return this._hasChanges;
  }

  showWarning = false;
  forceModalClose = false;

  closeManualDiff = {
    name: 'modal-close',
    eventMatch: { key: 'Escape' },
    matchCallback: ($event: KeyboardEvent) => {
      if (this.appStateService.loading || !this.modalService.openModal) {
        return;
      }
      this.preventDefaultAndPropagation($event);
      const shouldCloseTheModal = this.beforeModalClose();
      if (shouldCloseTheModal) {
        this.closeModal();
      }
    },
    removeOnMatch: true,
  } as KeyboardAction;

  focusManualDiff = {
    name: 'focus-manual-diff',
    eventMatch: { key: 'F3' },
    matchCallback: ($event: KeyboardEvent) => {
      this.preventDefaultAndPropagation($event);

      if (this.appStateService.loading || this.modalService.openModal) {
        return;
      }

      if (!this.isModalOpen) {
        this.focusManualDiffTriggerButton();
      }
    },
    removeOnMatch: false,
  } as KeyboardAction;

  resetManualDiff = {
    name: 'reset-manual-diff',
    eventMatch: {
      key: 'r',
      altKey: true,
    },
    matchCallback: ($event: KeyboardEvent) => {
      if (this.appStateService.loading) {
        return;
      }
      this.preventDefaultAndPropagation($event);
      this.reset();
    },
    removeOnMatch: false,
  } as KeyboardAction;

  saveManualDiff = {
    name: 'save-manual-diff',
    eventMatch: { key: 's', altKey: true },
    matchCallback: ($event: KeyboardEvent) => {
      if (this.appStateService.loading) {
        return;
      }
      this.preventDefaultAndPropagation($event);
      this.save();
    },
  } as KeyboardAction;

  @HostListener('document:keydown', ['$event'])
  onKeydown($event: KeyboardEvent) {
    if (this.appStateService.loading) {
      return;
    }

    // Close modal when keydown is captured from outside the modal (e.g. when header accession search has focus);
    if (document.activeElement.closest('#accession-search')) {
      this.closeModal(true);
      return;
    }

    const allowedKeys = this.configService.getAllowedKeys();
    const eventKey = this.modifyEventKey($event.key, allowedKeys); // done to support both '.' and ',' when configured only one of them
    const isAllowed = allowedKeys.some((key) => key === eventKey);

    if (this.visible && isAllowed) {
      const updateAssay = this.getAssayByShortcut(eventKey);

      $event.altKey ? this.decrement(updateAssay) : this.increment(updateAssay);
    }
  }

  constructor(
    private translate: TranslateService,
    public configService: WorkspaceConfigService,
    private keyboardService: KeyboardService,
    public appStateService: AppStateService,
    private manualDiffService: ManualDiffService,
    private modalService: ModalContainerService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    /*
    https://kenney.nl/assets/interface-sounds
    License: (CC0 1.0 Universal)
    You're free to use these game assets in any project, personal or commercial.
    There's no need to ask permission before using these.
    Giving attribution is not required, but is greatly appreciated!
    */
    this.audioPromptComplete = new Audio('../../../assets/audio/confirmation_001.ogg');
    this.audioPromptWarn = new Audio('../../../assets/audio/bong_001.ogg');

    this.keyboardService.addActions([this.focusManualDiff]);

    this.manualDiffForm = new FormGroup({
      target: new FormControl(100),
      total: new FormControl(0),
    });

    this.manualDiffForm.controls.total.valueChanges
      .pipe(
        tap((value) => {
          if (this.showWarning) {
            this.cancelClose();
          }
          this.updateManualDiffDisplayTotal(value);
          this.playAudioPrompt();
        }),
        debounceTime(this.manualDiffCountsDebounce),
        switchMap(() => {
          return this.manualDiffService.calculateManualDiffCounts(this.manualDiffAssays);
        })
      )
      .subscribe((countsUpdate) => {
        this.manualDiffCounts = this.manualDiffService.manualDiffCounts;
      });
  }

  ngOnDestroy() {
    this.keyboardService.removeAction(this.focusManualDiff);
    this.manualDiffSaveSub?.unsubscribe();
  }

  modifyEventKey(key: string, allowedKeys: string[]): string {
    if (key === '.' && allowedKeys.includes(',')) {
      return ',';
    } else if (key === ',' && allowedKeys.includes('.')) {
      return '.';
    } else {
      return key;
    }
  }

  confirmClose() {
    this.modalContent.preventClose = false;
    this.forceModalClose = true;
    this.modalContent.close();
  }

  cancelClose(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.modalContent.preventClose = false;
    this.showWarning = false;
    this.focusTargetSelection();
  }

  beforeModalClose(): boolean {
    if (this._hasChanges && !this.forceModalClose) {
      this.modalContent.preventClose = true;
      this.showWarning = true;
      requestAnimationFrame(() => {
        // at this time button still don't exist
        if (this.warningQuitButton) {
          this.warningQuitButton.nativeElement.focus();
        }
      });
      return false;
    }

    this.modalContent.preventClose = false;
    this.forceModalClose = false;
    return true;
  }

  closeModal(preventFocus: boolean = false, initializeManualDiffAssay: boolean = true): void {
    if (!this.visible) {
      return;
    }

    this.visible = false;
    // Remove action here so it frees up the keybinding for other contexts
    this.keyboardService.removeAction(this.resetManualDiff);
    this.keyboardService.removeAction(this.saveManualDiff);
    this.reset(initializeManualDiffAssay);
    if (!preventFocus) {
      this.focusManualDiffTriggerButton();
    }
  }

  openModal(event): void {
    // Prevent open via keyboard or mouse click when loading
    // Open modal while loading is ok when not initiated by user (e.g. when navigating through the queue)
    if (this.appStateService.loading && event) {
      return;
    }

    this.keyboardService.addActions([this.closeManualDiff, this.resetManualDiff, this.saveManualDiff]);
    this.manualDiffAssays = this.configService.initializeManualDiffAssays();

    this.manualDiffNumpadAssays = this.getManualDiffNumpadAssays();

    this.manualDiffForm.setValue({ target: this.configService.defaultTarget, total: 0 });
    this.showWarning = false;
    this.visible = true;

    this.focusTargetSelection();
  }

  focusTargetSelection(): void {
    this.targetSelect.nativeElement.focus();
  }

  focusManualDiffTriggerButton(): void {
    if (this.visible || this.appStateService.loading) {
      return;
    }
    this.manualDiffTriggerButton.nativeElement.focus();
  }

  preventDefaultAndPropagation(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  reset(initializeManualDiffAssay: boolean = true): void {
    // Reset form counts, but preserve user target selection on explicit reset
    this.manualDiffForm.setValue({ target: this.manualDiffForm.controls.target.value, total: 0 });

    if (initializeManualDiffAssay) {
      // Reload configuration and counts
      this.manualDiffAssays = this.configService.initializeManualDiffAssays();
    }

    // Reset grid view
    this.manualDiffNumpadAssays = this.getManualDiffNumpadAssays();

    // Clear displayed calculations
    this.manualDiffCounts = [];
  }

  getAssayByShortcut(key: string): ManualDiffAssay {
    return this.manualDiffAssays.filter((assay) => assay.shortCut === key)[0];
  }

  increment(manualDiffAssay: ManualDiffAssay, event?: Event): void {
    if (this.appStateService.loading) {
      throw new Error('Can not increment while loading');
    }

    if (event) {
      this.preventDefaultAndPropagation(event);
      this.focusTargetSelection();
    }

    if (!this.isTargetSatisfied()) {
      this.setSuspendAudioPrompt(manualDiffAssay);

      // Update model
      manualDiffAssay.incrementCount();

      // Update observable
      const updateValue = this.manualDiffForm.controls.total.value;
      this.manualDiffForm.controls.total.setValue(updateValue + 1);
    }
  }

  decrement(manualDiffAssay: ManualDiffAssay, event?: Event): void {
    if (this.appStateService.loading) {
      throw new Error('Can not decrement while loading');
    }

    if (event) {
      this.preventDefaultAndPropagation(event);
      this.focusTargetSelection();
    }

    if (manualDiffAssay.count > 0) {
      this.setSuspendAudioPrompt(manualDiffAssay);

      // Update model
      manualDiffAssay.decrementCount();

      // Update observable
      const updateValue = this.manualDiffForm.controls.total.value;
      this.manualDiffForm.controls.total.setValue(updateValue - 1);
    }
  }

  // Do not play audio when incrementing or decrementing NRBC count regardless of target/total
  setSuspendAudioPrompt(manualDiffAssay): void {
    this.suspendAudioPrompt = manualDiffAssay.standardIdexxAssay === this.configService.nrbcAssayId;
  }

  isTargetSatisfied(): boolean {
    return this.manualDiffDisplayTotal >= this.manualDiffForm.controls.target.value;
  }

  // Special handling for keydown events while HTML select or option has focus
  handleTargetKeydown($event: KeyboardEvent) {
    const isShortcut = this.configService.getAllowedKeys().find((key) => key === $event.key);
    const preventNumericDefault = !isNaN(Number($event.key)) && !isNaN(parseFloat($event.key));

    if (isShortcut || preventNumericDefault) {
      this.preventDefaultAndPropagation($event);
      this.onKeydown($event);
    }
  }

  playAudioPrompt(): void {
    if (this.suspendAudioPrompt) {
      return;
    }

    this.manualDiffDisplayTotalVsTarget = this.manualDiffForm.controls.target.value - this.manualDiffDisplayTotal;

    // Play confirmation if target is satisfied. Otherwise play prompt when approaching target
    // NOTE: Audio.play returns a promise here. We are ignoring that for now, but we may find a use case where we
    // care about the play event in an observable context.
    if (this.isTargetSatisfied()) {
      this.audioPromptComplete.play();
    } else if (this.manualDiffDisplayTotalVsTarget < 4) {
      this.audioPromptWarn.play();
    }
  }

  getNRBCCount(): number {
    const nrbcAssay = this.manualDiffAssays?.find(
      (assay) => assay.standardIdexxAssay === this.configService.nrbcAssayId
    );
    return nrbcAssay?.count || 0;
  }

  updateManualDiffDisplayTotal(value: number): void {
    this.manualDiffDisplayTotal = value - this.getNRBCCount();
    this._hasChanges = this.manualDiffDisplayTotal > 0;
  }

  resultByAssay(assay: ManualDiffAssay) {
    let resultAssay;

    if (this.manualDiffCounts?.length > 0) {
      resultAssay = this.manualDiffCounts.filter(
        (assayCount: ManualDiffCount) => assayCount.standardIdexxAssay === assay.standardIdexxAssay
      )[0];
    }

    // Do not display zero
    return resultAssay?.value || '';
  }

  save(event?: MouseEvent) {
    if (this.appStateService.loading) {
      const errorMessage = 'Can not save while loading';
      this.loggerService.logAction('can-not-save-manual-diff-while-loading-error', { errorMessage });
      throw new Error(errorMessage);
    }

    if (!this._hasChanges) {
      return;
    }

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const savableCounts = this.getSavableCounts();

    if (savableCounts.length > 0) {
      this.appStateService.loading = true;

      this.manualDiffSaveSub = this.manualDiffService
        .addManualDiff(savableCounts)
        .pipe(
          tap(() => {
            this.loggerService.logAction('cbclog-manual-diff-saved', { savableCounts });
            this.modalContent.close();
          })
        )
        .subscribe();
    }
  }

  getSavableCounts() {
    let savableCounts: ManualDiffSaveCount[] = [];
    const assaysWithCounts = this.manualDiffAssays.filter((assay) => assay.count > 0);
    if (assaysWithCounts.length > 0) {
      savableCounts = assaysWithCounts.map((assay) => {
        return {
          assay: assay.standardIdexxAssay,
          count: assay.count,
        } as ManualDiffSaveCount;
      });
    }
    return savableCounts;
  }

  // This method constructs a model that visually maps to a physical numpad in the template.
  getManualDiffNumpadAssays() {
    const numpadMapping = ['/', '*', '7', '8', '9', '4', '5', '6', '1', '2', '3', '0', '.', ',', '-', '+'];
    const numpadAssays = new Array(15);

    this.manualDiffAssays?.forEach((assay) => {
      const numpadAddress = numpadMapping.findIndex((mapping) => mapping === assay.shortCut);
      // The numpad decimal separator key is different in different regions
      if (assay.shortCut === '.' || assay.shortCut === ',') {
        numpadAssays[12] = assay;
      } else {
        numpadAssays[numpadAddress] = assay;
      }
    });

    return numpadAssays;
  }
}
